<template>
  <v-container fluid grid-list-md>
    <AppTitleBar>
      <template v-slot:title v-if="loading">{{ $t('labels.firm') }}</template>
      <template v-slot:title v-else>{{ firm.display }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="dismiss" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.viewBack }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.firms') }}</span>
        </v-tooltip>
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="editFirm" v-on="on">
              <v-icon small v-text="$vuetify.icons.values.edit" />
            </v-btn>
          </template>
          <span>{{ $t('labels.edit') }}</span>
        </v-tooltip>
        <v-btn icon @click="doDeleteFirm(item)">
          <v-icon>{{ $vuetify.icons.values.trash }}</v-icon>
        </v-btn>
      </template>
    </AppTitleBar>

    <v-loading v-if="loading" />
    <v-row v-else>
      <v-col cols="12">
        <v-toolbar dense flat class="transparent">
          <v-toolbar-title>
            {{ $t('labels.overview') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <v-toolbar dense flat class="transparent">
          <v-toolbar-title>
            {{ $t('labels.agencies') }}
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon small @click="createAgency" v-on="on">
                <v-icon small v-text="$vuetify.icons.values.add" />
              </v-btn>
            </template>
            <span>{{ $t('labels.newAgency') }}</span>
          </v-tooltip>
        </v-toolbar>
        <FirmAgencies :firm-id="firm.id" />
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AppTitleBar, VLoading } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
import firmPerimeter from '../perimeters'
import { FirmAgencies } from '../components'

export default {
  name: 'FirmPage',
  components: {
    AppTitleBar,
    FirmAgencies,
    VLoading
  },
  perimeters: [firmPerimeter],
  mixins: [Modal],
  props: {
    firmId: { type: [Number, String], required: true }
  },
  data: () => ({
    activeTab: null,
    returnPath: { name: 'firms' },
    loading: true
  }),
  computed: {
    ...mapState(NAMESPACE, ['firm'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getFirm', 'deleteFirm', 'clearFirm']),
    doDeleteFirm(item) {
      return this.deleteFirm(item).then(() => {
        this.$router.push({ name: 'firms' })
      })
    },
    editFirm() {
      this.$router.push({ name: 'editFirm', params: { firmId: this.firmId } })
    },
    createAgency() {
      this.$router.push({ name: 'editAgency', params: { agencyId: 'new' } })
    }
  },
  created() {
    this.getFirm(this.firmId).then(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearFirm()
  }
}
</script>

<style scoped>
.tools__area {
  min-height: 200px;
}
</style>
